import moment from 'moment';
import "magnific-popup";

jQuery(document).ready(function ($) {

  // Apple popup
  $('#apple-btn').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: '#apple-popup',
        type: 'inline'
      }
    });
    var sm = new SMEAppleMusic({
      ae: {
        ae: "6f031bfdb1f3b11f33997bd5fbf79af9c610f313cdae47526986a5c48a00888d",
        brand_id: "5150554",
        segment_id: "2273631",
        activities: '{"actions":{"presave":155035},"mailing_list_optins":{"a0S0800000g8wZOEAY":155036}}'
      },
      am: {
        dev_token: "", // Add your Apple Music Developer Token here
        save_mode: "library",
        custom_playlist_name: "",
        new_apple_presave: true,
        resources: {
          playlists: [],
          albums: []
        }
      },
      sf: {
        form: "506135",
        default_mailing_list: ""
      },
      smf: {
        campaign_id: "506135",
        campaign_key: "dae4d7b406331605e8422c2dc44fa616"
      }
    });

    document.getElementById("apple_music_form").addEventListener("submit", function (e) {
      e.preventDefault();
      var email = document.getElementById("apple_music_email_address").value;
      sm.doActions(email, false, ["a0S0800000g8wZOEAY"], false).then(function (res) {
        console.log('Success:', res);
        $.magnificPopup.open({
          items: {
            src: '#thanks',
            type: 'inline'
          }
        });
      }).catch(function (err) {
        console.log('Error:', err);
      });
    });
  });
});